import React, { useState } from "react";
import { motion } from "framer-motion";

import "../../components/styles/image-slider.css";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    const index = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(index);
  };

  const goToNextSlide = () => {
    const index = (currentIndex + 1) % images.length;
    setCurrentIndex(index);
  };

  return (
    <div className="image-slider">
      <div>
        <motion.div
          key={currentIndex} // Ensure each slide has a unique key for smooth transition
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ duration: 0.5 }}
        >
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        </motion.div>
      </div>

      <button className="nav-button prev" onClick={goToPrevSlide}>
        &lt;
      </button>
      <button className="nav-button next" onClick={goToNextSlide}>
        &gt;
      </button>
    </div>
  );
};

export default ImageSlider;

import React, { useState } from 'react'
import { Box, FormControl, MenuItem, Select } from "@mui/material";

import {countryList} from './countryList';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import "../styles/navbar.css";

const LanguageSelector = (props) => {
    const { t, i18n, colorChange } = props;
    const [language, setLanguage] = useState("en");
    
    
    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Box>
            <FormControl
              sx={{
                minWidth: 120,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
              }}
            >
              <Select
                SelectDisplayProps={{
                  style: { display: "flex", alignItems: "center", gap: "10px" },
                }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                }}
                id={colorChange ? "language-select-color" : "langauge-select"}
                value={language}
                onChange={handleChangeLanguage}
              >
                {countryList.map((option, key) => (
                  <MenuItem
                    className="language-item"
                    value={option.value}
                    key={key}
                  >
                    <img
                      className="country-flags"
                      src={option.flag_icon}
                      alt={option.label}
                    />
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </Box>
    )
}

const enhance = compose(withTranslation("translation"));

export default enhance(LanguageSelector);
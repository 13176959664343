import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Reveal from "../../../utils/motion/reveal";
import { h1 } from "../../../assets/hoghilag";
import Footer from "../../Footer/Footer";

const Hogilag = (props) => {
  const { t } = props;
  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${h1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Menu.Hoghilag")}
                  </span>
                </Reveal>
              </h1>

              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.Projects")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.Hoghilag")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
          <section className="section-container">
            <div className="section-row">
              <div className="section-col1">
                <div>
                  {/* <Reveal>
                    <div className="section-image">
                      <img src={house1} alt="sighisoara" />
                    </div>
                  </Reveal> */}

                  <div className="section-text">
                    <Reveal>
                      <div className="section-text-title">
                        <h2>{t("Hogilag.HogilagTitle")}</h2>
                      </div>
                    </Reveal>

                    <Reveal>
                      <div className="section-text-p">
                        <p>{t("Hogilag.HogilagDesc1")}</p>

                        <p>{t("Hogilag.HogilagDesc2")}</p>

                        <p>{t("Hogilag.HogilagDesc3")}</p>

                        <p>{t("Hogilag.HogilagDesc4")}</p>

                        <p>{t("Hogilag.HogilagDesc5")}</p>
                        <p>{t("Hogilag.HogilagDesc6")}</p>
                        <p>{t("Hogilag.HogilagDesc7")}</p>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Hogilag);

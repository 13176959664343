import React, { useEffect, useState } from 'react'
import NewsCard from './NewsCard'

import '../styles/newsfeed.css';
import Reveal from '../../utils/motion/reveal';
import { Button, Dialog } from '@mui/material';

import {backgroundImage, rondola, rondola2, rondola3, rondola4, rondola5} from '../../assets';
import Dialog1 from './dialogs/Dialog1';
import Dialog2 from './dialogs/Dialog2';
import Dialog3 from './dialogs/Dialog3';
import Dialog4 from './dialogs/Dialog4';

import { Link } from 'react-router-dom'
import { house1 } from '../../assets/houseOfJoy';

// const hardcoded_Data = [
//     {
//         title: "Marking Milestone fall 2022",
//         picture: n1,
//         description: "Is time again to joyfully help, give and tell the good news about Christmas and Gods love!"
//     },
//     {
//         title: "Anunt Lansare Proiect Comunitati marginalizate",
//         picture: n2,
//         description: "Is time again to joyfully help, give and tell the good news about Christmas and Gods love!"
//     },
//     {
//         title: "Warm Feet 27th to 31st Dec 2022",
//         picture: n3,
//         description: "Is time again to joyfully help, give and tell the good news about Christmas and Gods love!"
//     },
    
// ]

const NewsFeed = () => {
    return (
        <Reveal>
            <div className='news-container' style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="news-container-content">
            <Reveal>
                <div className="news-title">
                    <h2>
                        News
                    </h2>
                </div>
            </Reveal>

            <Reveal>
            <div className='news_feed'>

            <NewsCard
                    title="Albesti summer camp"
                    image={house1}
                    description={"Families in unity and diversity!"}
                    linkDestination={"/news/Albesti-summer-camp"}
                />

                <NewsCard 
                    title="Spread your wings and fly"
                    image={rondola5}
                    description={"Summer Camp with Goldberich and Hoghilag children and teens"}
                    linkDestination={"/news/Spread-your-wings-and-fly"}
                />

                <NewsCard 
                    title="Spring update 2024"
                    image={rondola4}
                    description={"A big thank you to all of you who have been giving towards the work in Sighisoara in different ways during 2024."}
                    linkDestination={"/news/Spring-update"}
                />

                <NewsCard 
                    title="Warm Feet 2023"
                    image={rondola}
                    description={"Once again, the project was a blessing for thousands of children and families in need, as well was bringing comfort and help for those living in difficult circumstances around us."}
                    linkDestination={"/news/Warm-Feet-2023"}
                />

                {/* <NewsCard 
                    title="Warm Feet in the pandemic year"
                    image={rondola2}
                    description={"As we all know 2020 was an atypical year in all its aspects.In this context, with much international restrictions, outreach teams from abroad were canceled, still we did not want to give up on the Warm feet, knowing that within the actual situation the needs are even greater."}
                    openDialog={handleOpen2}
                /> */}
            
            </div>
            </Reveal>

            <div className="news-button">
                <Link to={"/news"}>
                    <Button variant="contained" size="large" className="news-button-style">All news</Button>
                </Link>
            </div>
    
            </div>
        </div>
        </Reveal>
        
    )
}

export default NewsFeed
import React from "react";
import { house1 } from "../../../assets/houseOfJoy";
import Footer from "../../Footer/Footer";
import { rondola, rondola4, rondola5 } from "../../../assets";

const NewsPage4 = () => {

    return (
        <div>
            <div className="page-container">
                <div
                    className="page-default-banner"
                    style={{
                        backgroundImage: `url(${house1})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        padding: "145px 0",
                    }}
                >
                    <div className="article">
                        <div className="row-article">
                            <h1>
                                <span class="article-title">Daycamps in Albesti</span>
                            </h1>
                            <div className="article-info">
                                <div className="article-data">
                                    <span>By Ligia Pintican</span>
                                </div>
                                <div className="article-data">1st to 5th July 2024</div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section-container pd-t-40">
                    <div className="article-content-row article-width">
                        <div className="section-col1">
                            <div className="dialog-container-content">
                                <p>
                                    Families in unity and diversity!
                                    As flowers have different shapes, colors and yet, so are we, even in the same family.
                                    This summer, in the 1st week of July, we spend every day with the children and families from the village of Albesti.  We celebrated and learned about our diversity even in our smaller or larger family, our community.
                                    Families created their own flag with which to brand their own gifts.
                                    Then, from all the small flags, the big community flag was made.
                                </p>
                            </div>

                            <div>
                                {/* <ImageSlider images={images} /> */}
                            </div>
                            <div className="dialog-container-content">
                                <p>
                                The children made their own ceramic pot and planted a beautiful rose. They learnt about plant life; how they grow and develop from a little seed, roots, leaves and flowers. The days were full of experiences and joys. Then, identifying the gifts or talents that make children unique was also part of some activities.
                                </p>

                                <p>
                                The teenagers were challenged to cook the big goulash together. We talked and imagined what kind of relationships we want in our families, what kind of wife or husband I want to become? Of course we played and laughed and had a lot of fun.
                                </p>
                            </div>

                            <div>
                                {/* <ImageSlider images={images2} /> */}
                            </div>

                            <div className="dialog-container-content">
                                <p>
                                Life is like a river, when we all contribute it grows but the source is always the same: our Loving Heavenly Father.
                                </p>
                            </div>

                            <div >
                                {/* <img className="article-image-style" src={singlePoza} alt={"team"} /> */}
                            </div>

                            <div className="dialog-container-content">
                                <p>
                                Last evening we celebrated with the great community. With music, drama,  and a word of encouragement to be a family that helps, loves and is ready to serve each another. Then we ate something good and everyone was welcome; the little ones as well as the old ones, like in a big family!
                                </p>

                            </div>
                        </div>

                        <div className="section-col1">
                <div className="sidebar_widget has_border mb-40">
                    <div className="sidebar_title">
                        <h4 class="sidebar_title_text has_border"><span></span>Latest News</h4>
                    </div>

                    <div className="feed_widget-content">
                        <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div>
                        <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola4} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spring update 2024</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th May 2024
                                </a>
                            </div>
                        </div>
                        {/* <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div> */}
                        <div class="single_feed_widget">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Warm Feet 2023</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>20 January 2024
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}

export default NewsPage4
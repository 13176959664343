import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Footer from "../../Footer/Footer";
import Reveal from "../../../utils/motion/reveal";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  history1,
  history2,
  history3,
  history4,
  history5,
} from "../../../assets/history";

const History = (props) => {
  const { t } = props;
  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${history1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">{t("Menu.History")}</span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.AboutUs")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.History")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>
        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div className="section-text">
                <Reveal>
                  <div className="section-text-title">
                    <h2>{t("History.HistoryTitle")}</h2>
                  </div>
                </Reveal>

                <div className="section-text-p">
                  <Reveal>
                    <p>{t("History.HistoryDesc1")}</p>
                  </Reveal>

                  <Reveal>
                    <div className="image-row">
                      <div className="image-col">
                        <img src={history2} alt="rhema history image 1" />
                      </div>
                      <div className="image-col">
                        <img src={history3} alt="rhema history image 2"/>
                      </div>
                    </div>
                  </Reveal>

                  <Reveal>
                    <p>{t("History.HistoryDesc2")}</p>
                  </Reveal>

                  <Reveal>
                    <div className="image-row">
                      <div className="image-col">
                        <img src={history4} alt="rhema history image"/>
                      </div>
                      <div className="image-col">
                        <img src={history5} alt="rhema history image"/>
                      </div>
                    </div>
                  </Reveal>

                  <Reveal>
                    <p>{t("History.HistoryDesc3")}</p>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(History);

import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../Footer/Footer";
import {
  building1,
  building2,
  building3,
  building4,
  building5,
} from "../../../assets/building";

const BuildingTeams = (props) => {
  const { t } = props;

  const gridItemStyle = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${building1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Menu.BuildingTeams")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.Projects")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">
                    {t("Menu.BuildingTeams")}
                  </li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                {/* <Reveal>
                  <div className="section-image">
                    <img src={house1} alt="sighisoara" />
                  </div>
                </Reveal> */}

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Building.Title")}</h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>{t("Building.BuildingDesc1")}</p>
                      <p>{t("Building.BuildingDesc2")}</p>
                      <p>{t("Building.BuildingDesc3")}</p>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
          <Reveal>
            <section>
              <div className="albesti-grid-images">
                <div style={gridItemStyle(building2)}></div>
                <div style={gridItemStyle(building3)}></div>
                <div style={gridItemStyle(building4)}></div>
                <div style={gridItemStyle(building5)}></div>
              </div>
            </section>
          </Reveal>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(BuildingTeams);

import React, { useEffect, useState } from 'react'

import './panelCollapsible.css';

const PanelCollapsible = ({ title, content }) => {

    const [isActive, setIsActive] = useState(false);

  const togglePanel = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`panel ${isActive ? 'active' : ''}`}>
    <div className="panel-title" onClick={togglePanel}>
      {title}
    </div>
    <div className="panel-content">
      <div className="panel-content-position">{content}</div>
    </div>
  </div>
  )
}

export default PanelCollapsible;
import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import {
  internship1,
  internship2,
  internship3,
  internship4,
  internship5,
  internship6,
  internship7,
  internship8,
  internship9,
  mainPic,
} from "../../assets/internship";

import "../styles/internship.css";
import Masonry from "react-responsive-masonry";
import PanelCollapsible from "../../utils/custom/panelCollapsible";
import Footer from "../Footer/Footer";
import Reveal from "../../utils/motion/reveal";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ColorButton from "../../utils/custom/colorButton";
import { Link } from "react-router-dom";

const Internship = (props) => {
  const { t } = props;
  return (
    <>
      <div>
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${mainPic})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="row">
            <h1>
              <Reveal>
                <span className="color-big-heading">
                  {t("Internship.InternshipTitle")}
                </span>
              </Reveal>
            </h1>
            <Reveal>
              <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">Get Involved</li>
                <li className="breadcrumb-item">
                  <ArrowForwardIcon></ArrowForwardIcon>
                </li>
                <li className="breadcrumb-item ">
                  {t("Internship.InternshipTitle")}
                </li>
              </ul>
            </Reveal>
          </div>
        </div>
      </div>

      <div>
        <Reveal>
          <div className="internship-container">
            <div className="internship-container-content">
              <h2>{t("Internship.InternshipTitle")}</h2>
              <p>{t("Internship.InternshipDesc1")}</p>
              <p>{t("Internship.InternshipDesc2")}</p>
            </div>
            <div className="internship-container-image">
              <img alt="internship" src={internship2} />
            </div>
          </div>
        </Reveal>

        <Reveal>
          <div className="internship-album">
            <Masonry columnsCount={3} gutter="10px">
              <img
                className="album-image"
                src={internship1}
                alt="internship1"
              />
              <img
                className="album-image"
                src={internship4}
                alt="internship3"
              />
              <img
                className="album-image"
                src={internship3}
                alt="internship2"
              />
              <img
                className="album-image"
                src={internship8}
                alt="internship6"
              />
              <img
                className="album-image"
                src={internship5}
                alt="internship4"
              />
              <img
                className="album-image"
                src={internship6}
                alt="internship5"
              />
            </Masonry>
          </div>
        </Reveal>

        <Reveal>
          <div className="internship-questions-section">
            <div className="questions-headline">
              <h2>Internship – Q & A</h2>
            </div>

            <div className="questions-section">
              <div className="questions-image-container">
                <img
                  className="questions-image"
                  src={internship9}
                  alt="internship9"
                />
              </div>
              <div className="questions-container">
                <PanelCollapsible
                  title={"Who should apply for this program?"}
                  content={
                    <>
                      <ul>
                        <li>{t("Internship.InternshipQ1R1")}</li>
                        <li>{t("Internship.InternshipQ1R2")}</li>
                        <li>{t("Internship.InternshipQ1R3")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ1S1")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ1R4")}</li>
                        <li>{t("Internship.InternshipQ1R5")}</li>
                        <li>{t("Internship.InternshipQ1R6")}</li>
                        <li>{t("Internship.InternshipQ1R7")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ1S2")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ1R8")}</li>
                        <li>{t("Internship.InternshipQ1R9")}</li>
                        <li>{t("Internship.InternshipQ1R10")}</li>
                        <li>{t("Internship.InternshipQ1R11")}</li>
                      </ul>
                    </>
                  }
                />

                <PanelCollapsible
                  title={"What is the internship about?"}
                  content={
                    <>
                      <p>
                        <strong>{t("Internship.InternshipQ2S1")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ2R1")}</li>
                        <li>{t("Internship.InternshipQ2R2")}</li>
                        <li>{t("Internship.InternshipQ2R3")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ2S2")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ2R4")}</li>
                        <li>{t("Internship.InternshipQ2R5")}</li>
                        <li>{t("Internship.InternshipQ2R6")}</li>
                        <li>{t("Internship.InternshipQ2R7")}</li>
                        <li>{t("Internship.InternshipQ2R8")}</li>
                        <li>{t("Internship.InternshipQ2R9")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ2S3")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ2R10")}</li>
                        <li>{t("Internship.InternshipQ2R11")} 😊</li>
                      </ul>
                    </>
                  }
                />
                <PanelCollapsible
                  title={"Where is the internship?"}
                  content={
                    <>
                      <p>
                        <strong>{t("Internship.InternshipQ1S1")}</strong>
                      </p>
                      <ul>
                        <li>
                          {t("Internship.InternshipQ3R1")}{" "}
                          <a
                            style={{ color: "blue" }}
                            href="http://romaniatourism.com/sighisoara.html"
                          >
                            http://romaniatourism.com/sighisoara.html
                          </a>
                        </li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ3S2")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ3R2")}</li>
                        <li>{t("Internship.InternshipQ3R3")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ3S3")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ3R4")}</li>
                      </ul>
                    </>
                  }
                />

                <PanelCollapsible
                  title={"What is the cost of the internship?"}
                  content={
                    <>
                      <p>
                        <strong>{t("Internship.InternshipQ4S1")}</strong>
                      </p>
                      <ul>
                        <li>{t("Internship.InternshipQ4R1")}</li>
                        <li>{t("Internship.InternshipQ4R2")}</li>
                      </ul>
                      <p>
                        <strong>{t("Internship.InternshipQ4R3")}</strong>
                      </p>
                      <p>
                        <strong>{t("Internship.InternshipQ4R4")}</strong>
                      </p>
                    </>
                  }
                />
                <div
                style={{ paddingTop: "2rem", paddingBottom: "6rem" }}
                className="donation-button"
              >
                <Link to="">
                  <ColorButton variant="contained">
                    {t("Internship.ContactUs")}
                  </ColorButton>
                </Link>
              </div>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Internship);

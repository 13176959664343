import React, { useState, useRef, useEffect } from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Tooltip from '@mui/material/Tooltip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Reveal from "../../utils/motion/reveal";
import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import "../styles/donation.css";

import { donation, qrCode } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { afterschool, camp, general, social } from "../../assets/donations";
import Footer from "../Footer/Footer";
import ColorButton from "../../utils/custom/colorButton";
import { countryList } from "./countryList";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ColorButtonDonation = styled(Button)(({ theme }) => ({
  width: "150px",
  color: red[500],
  transition: "all 0.3s ease",
  "&:hover .favorite-icon": {
    transform: "scaleX(-1)", // Flip the icon on hover
    transition: "transform 0.3s ease", // Smooth transition effect for flipping
  },
}));

const Donation = (props) => {
  const { t } = props;

  const [openDialog1, setOpenDialog1] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);

  const [selectRegion, setSelectRegion] = useState("");

  const handleClickOpen1 = () => {
    setOpenDialog1(true);
  };

  const handleClickOpen2 = () => {
    setOpenDialog2(true);
  };

  const handleCloseDialog1 = () => {
    setOpenDialog1(false);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const handleClickOpen3 = () => {
    setOpenDialog3(true);
  };

  const handleClickOpen4 = () => {
    setOpenDialog4(true);
  };

  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
  };

  const handleCloseDialog4 = () => {
    setOpenDialog4(false);
  };

  const handleChangeRegion = (event) => {
    setSelectRegion(event.target.value);
  };

  const handleOpenEuroAfterschoolLink = () => {
    window.open(
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KNGUKXTMWL2BG",
      "_blank"
    );
  };

  const handleOpenEuroSummerCampsLink = () => {
    window.open(
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4HTQ57VH7KJFJ",
      "_blank"
    );
  };

  const handleOpenUsaAfterschoolLink = () => {
    window.open(
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KRAEH5XUPGGBS",
      "_blank"
    );
  };

  const handleOpenUsaSummerCampsLink = () => {
    window.open(
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XSR2VSLVYT7KY",
      "_blank"
    );
  };

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${donation})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Menu.Donations")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.GetInvolved")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">
                    {t("Menu.Donations")}
                  </li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div className="section-text">
                <Reveal>
                  <div className="section-text-title">
                    <h2>{t("Donations.DonationTitle")}</h2>
                  </div>
                </Reveal>
                <Reveal>
                  <div className="section-text-p">
                    <p>{t("Donations.DonationDesc1")}</p>

                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <div className="donation-action">
          {/* <Reveal>
            <div className="donation-section-title">
              <h2>Projects</h2>
            </div>
          </Reveal> */}

          <div className="donation-cards-list">
            <Reveal>
              <Box className="donation-card-size"
                onClick={handleClickOpen1}
                sx={{
                  margin: "20px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)", // Moves the card up by 10px
                    cursor: "pointer",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)", // Adds shadow on hover
                  },
                }}
              >
                <Card sx={{ maxWidth: 600 }}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={afterschool}
                    alt="afterschool"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="donation-action-title"
                    >
                      Help a child get education
                    </Typography>
                    <p className="donation-action-p">
                      Support literacy, kindergarten and afterschool for
                      children whose parents cannot help them.
                    </p>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ justifyContent: "center", backgroundColor: "red", color: "white" }}
                  >
                    <FavoriteIcon className="favorite-icon"></FavoriteIcon>
                    Donate
                  </CardActions>
                </Card>
              </Box>
            </Reveal>

            <Reveal>
              <Box className="donation-card-size"
                onClick={handleClickOpen2}
                sx={{
                  margin: "20px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)", // Moves the card up by 10px
                    cursor: "pointer",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)", // Adds shadow on hover
                  },
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image={camp}
                    alt="Summer camps"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="donation-action-title"
                    >
                      Camps
                    </Typography>
                    <p className="donation-action-p">
                      Give a child the highlight of his year and a time to just
                      be a child
                    </p>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ justifyContent: "center", backgroundColor: "red", color: "white" }}
                  >

                    <FavoriteIcon className="favorite-icon"></FavoriteIcon>
                    Donate
                  </CardActions>
                </Card>
              </Box>
            </Reveal>

            <Reveal>
              <Box className="donation-card-size"
                onClick={handleClickOpen3}
                sx={{
                  margin: "20px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)", // Moves the card up by 10px
                    cursor: "pointer",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)", // Adds shadow on hover
                  },
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image={social}
                    alt="Social"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="donation-action-title"
                    >
                      Social & medical help
                    </Typography>
                    <p className="donation-action-p">
                      Medicine and medical checks, social support for families
                      living in extreme poverty
                    </p>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ justifyContent: "center", backgroundColor: "red", color: "white" }}
                  >

                    <FavoriteIcon className="favorite-icon"></FavoriteIcon>
                    Donate

                  </CardActions>
                </Card>
              </Box>
            </Reveal>

            <Reveal>
              <Box
                className="donation-card-size"
                onClick={handleClickOpen4}
                sx={{
                  margin: "20px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-10px)", // Moves the card up by 10px
                    cursor: "pointer",
                    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)", // Adds shadow on hover
                  },
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="300"
                    image={general}
                    alt="Summer camps"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="donation-action-title"
                    >
                      General donations
                    </Typography>
                    <p className="donation-action-p">
                      Supports the ministry, staff, repairs, improvements,
                      community development
                    </p>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ justifyContent: "center", backgroundColor: "red", color: "white" }}
                  >

                    <FavoriteIcon className="favorite-icon"></FavoriteIcon>
                    Donate
                  </CardActions>
                </Card>
              </Box>
            </Reveal>
          </div>
        </div>
      </div>

      <Dialog
        maxWidth={"sx"}
        open={openDialog1}
        onClose={handleCloseDialog1}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="donation-dialog-title">
          Help a child get education
        </DialogTitle>
        <DialogContent>
          <div className="donation-dialog-container">
            <Box className="select-position">
              <Typography>Select Region
                <Tooltip
                  title="Select the region you live in."
                  arrow
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'primary.main',
                        color: 'white',
                        fontSize: '0.75rem',
                        boxShadow: 2,
                        borderRadius: '4px',
                        padding: '4px 8px',
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    style={{
                      color: "#1976d2",
                      position: "absolute",
                      cursor: "pointer"
                    }}
                  />
                </Tooltip>

              </Typography>
              <FormControl
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                }}
              >
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "130px",
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "red",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                  }}
                  labelId="region-select-label"
                  label="Region"
                  value={selectRegion}
                  onChange={handleChangeRegion}
                >
                  {countryList.map((option, key) => (
                    <MenuItem
                      className="language-item"
                      value={option.value}
                      key={key}
                    >
                      <img
                        className="country-flags"
                        src={option.flag_icon}
                        alt={option.label}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {selectRegion === "ro" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "us" && (
            <div className="bank-transfer-container">
              <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenUsaAfterschoolLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "eu" && (
            <div className="bank-transfer-container">
              <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenEuroAfterschoolLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div>
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}

          {selectRegion === "se" && (
            <div className="bank-transfer-container">
              <div className="">
                <p style={{ textAlign: "center" }}>Donate with:</p>
                <div>
                  <img
                    // className="flag-image"
                    src={qrCode}
                    alt="qr code sweden"
                  />
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>Habo Missionförsamling Bankgiro 779-9455</p>
                <p>Swish 123 453 55 30 Mark Romanian ev project</p>
              </div>
            </div>
          )}

          {selectRegion === "de" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">

                <p>
                  Important! The intended purpose should be noted on the
                  transaction
                </p>

                <div>
                  <iframe src="https://smg.payrexx.com/de/pay?cid=622a4754&appview=1" allow="payment *" width="100%" height="800" id="payrexx-embed"></iframe>
                </div>

                <p>
                  For bank transfers please use the following account
                  information
                </p>

              </div>

              <div className="bank-transfer-content">
                <p>IBAN: DE37 7605 0101 0001 1284 48</p>
                <p>BIC: SSKNDE77XX</p>
                <p>HILFSVERK LEBENSBROT</p>
                <p>SPARKASSE NURNBERG </p>
              </div>
            </div>
          )}

          {selectRegion === "ch" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>IBAN CH92 0900 0000 8004 2881 3</p>
                <p>
                  Kontoinhaber: Schweizerische Missionsgemeinschaft, 8404
                  Winterthur,
                </p>
                <p>Fundatia Metro Ministries Romania Projekt 494030</p>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton autoFocus onClick={handleCloseDialog1}>
            Close
          </ColorButton>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sx"}
        open={openDialog2}
        onClose={handleCloseDialog2}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="donation-dialog-title">Camps</DialogTitle>
        <DialogContent>
          <div className="donation-dialog-container">
            <Box className="select-position">
              <Typography>Select Region
                <Tooltip
                  title="Select the region you live in."
                  arrow
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'primary.main',
                        color: 'white',
                        fontSize: '0.75rem',
                        boxShadow: 2,
                        borderRadius: '4px',
                        padding: '4px 8px',
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    style={{
                      color: "#1976d2",
                      position: "absolute",
                      cursor: "pointer"
                    }}
                  />
                </Tooltip>

              </Typography>
              <FormControl
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                }}
              >
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "130px",
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "red",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                  }}
                  labelId="region-select-label"
                  label="Region"
                  value={selectRegion}
                  onChange={handleChangeRegion}
                >
                  {countryList.map((option, key) => (
                    <MenuItem
                      className="language-item"
                      value={option.value}
                      key={key}
                    >
                      <img
                        className="country-flags"
                        src={option.flag_icon}
                        alt={option.label}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {selectRegion === "ro" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "us" && (
            <div className="bank-transfer-container">
              <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenUsaSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "eu" && (
            <div className="bank-transfer-container">
              <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenEuroSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div>
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}

          {selectRegion === "se" && (
            <div className="bank-transfer-container">
              <div className="">
                <p style={{ textAlign: "center" }}>Donate with:</p>
                <div>
                  <img
                    // className="flag-image"
                    src={qrCode}
                    alt="qr code sweden"
                  />
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>Habo Missionförsamling Bankgiro 779-9455</p>
                <p>Swish 123 453 55 30 Mark Romanian ev project</p>
              </div>
            </div>
          )}

          {selectRegion === "de" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">

                <p>
                  Important! The intended purpose should be noted on the
                  transaction
                </p>

                <div>
                  <iframe src="https://smg.payrexx.com/de/pay?cid=622a4754&appview=1" allow="payment *" width="100%" height="800" id="payrexx-embed"></iframe>
                </div>

                <p>
                  For bank transfers please use the following account
                  information
                </p>

              </div>

              <div className="bank-transfer-content">
                <p>IBAN: DE37 7605 0101 0001 1284 48</p>
                <p>BIC: SSKNDE77XX</p>
                <p>HILFSVERK LEBENSBROT</p>
                <p>SPARKASSE NURNBERG </p>
              </div>
            </div>
          )}

          {selectRegion === "ch" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>IBAN CH92 0900 0000 8004 2881 3</p>
                <p>
                  Kontoinhaber: Schweizerische Missionsgemeinschaft, 8404
                  Winterthur,
                </p>
                <p>Fundatia Metro Ministries Romania Projekt 494030</p>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton autoFocus onClick={handleCloseDialog2}>
            Close
          </ColorButton>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sx"}
        open={openDialog3}
        onClose={handleCloseDialog3}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="donation-dialog-title">
          Social & Medical help
        </DialogTitle>
        <DialogContent>
          <div className="donation-dialog-container">
            <Box className="select-position">
              <Typography>Select Region
                <Tooltip
                  title="Select the region you live in."
                  arrow
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'primary.main',
                        color: 'white',
                        fontSize: '0.75rem',
                        boxShadow: 2,
                        borderRadius: '4px',
                        padding: '4px 8px',
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    style={{
                      color: "#1976d2",
                      position: "absolute",
                      cursor: "pointer"
                    }}
                  />
                </Tooltip>

              </Typography>
              <FormControl
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                }}
              >
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "130px",
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "red",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                  }}
                  labelId="region-select-label"
                  label="Region"
                  value={selectRegion}
                  onChange={handleChangeRegion}
                >
                  {countryList.map((option, key) => (
                    <MenuItem
                      className="language-item"
                      value={option.value}
                      key={key}
                    >
                      <img
                        className="country-flags"
                        src={option.flag_icon}
                        alt={option.label}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {selectRegion === "ro" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "us" && (
            <div className="bank-transfer-container">
              {/* <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenUsaSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div> */}

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "eu" && (
            <div className="bank-transfer-container">
              {/* <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenEuroSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div> */}
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}

          {selectRegion === "se" && (
            <div className="bank-transfer-container">
              <div className="">
                <p style={{ textAlign: "center" }}>Donate with:</p>
                <div>
                  <img
                    // className="flag-image"
                    src={qrCode}
                    alt="qr code sweden"
                  />
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>Habo Missionförsamling Bankgiro 779-9455</p>
                <p>Swish 123 453 55 30 Mark Romanian ev project</p>
              </div>
            </div>
          )}

          {selectRegion === "de" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">

                <p>
                  Important! The intended purpose should be noted on the
                  transaction
                </p>

                <div>
                  <iframe src="https://smg.payrexx.com/de/pay?cid=622a4754&appview=1" allow="payment *" width="100%" height="800" id="payrexx-embed"></iframe>
                </div>

                <p>
                  For bank transfers please use the following account
                  information
                </p>

              </div>

              <div className="bank-transfer-content">
                <p>IBAN: DE37 7605 0101 0001 1284 48</p>
                <p>BIC: SSKNDE77XX</p>
                <p>HILFSVERK LEBENSBROT</p>
                <p>SPARKASSE NURNBERG </p>
              </div>
            </div>
          )}

          {selectRegion === "ch" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>IBAN CH92 0900 0000 8004 2881 3</p>
                <p>
                  Kontoinhaber: Schweizerische Missionsgemeinschaft, 8404
                  Winterthur,
                </p>
                <p>Fundatia Metro Ministries Romania Projekt 494030</p>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton autoFocus onClick={handleCloseDialog3}>
            Close
          </ColorButton>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth={"sx"}
        open={openDialog4}
        onClose={handleCloseDialog4}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="donation-dialog-title">
          General donations
        </DialogTitle>
        <DialogContent>
          <div className="donation-dialog-container">
            <Box className="select-position">
              <Typography>Select Region
                <Tooltip
                  title="Select the region you live in."
                  arrow
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'primary.main',
                        color: 'white',
                        fontSize: '0.75rem',
                        boxShadow: 2,
                        borderRadius: '4px',
                        padding: '4px 8px',
                      },
                    },
                  }}
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    style={{
                      color: "#1976d2",
                      position: "absolute",
                      cursor: "pointer"
                    }}
                  />
                </Tooltip>

              </Typography>
              <FormControl
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                }}
              >
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: {
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "130px",
                      color: "black",
                    },
                  }}
                  sx={{
                    color: "red",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                  }}
                  labelId="region-select-label"
                  label="Region"
                  value={selectRegion}
                  onChange={handleChangeRegion}
                >
                  {countryList.map((option, key) => (
                    <MenuItem
                      className="language-item"
                      value={option.value}
                      key={key}
                    >
                      <img
                        className="country-flags"
                        src={option.flag_icon}
                        alt={option.label}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {selectRegion === "ro" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "us" && (
            <div className="bank-transfer-container">
              {/* <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenUsaSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div> */}

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}
          {selectRegion === "eu" && (
            <div className="bank-transfer-container">
              {/* <div className="donations-links">
                <p>Donate with:</p>
                <div className="donation-dialog-options">
                  <div
                    className="donation-action-wrapper"
                    onClick={handleOpenEuroSummerCampsLink}
                  >
                    <img
                      className="flag-image"
                      src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                      alt="PayPal"
                    />
                  </div>
                </div>
              </div> */}
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>SWIFT: BTRLRO22MSA</p>
                <p>BANCA TRANSILVANIA SA AGENTIA SIGHISOARA</p>
                <p>
                  STR. Morii 14-18 SIGHISOARA, COD 545400 JUD. MURES, ROMANIA
                </p>
                <p>
                  Recipient: FUNDATIA METRO MINISTRIES Str Zugravilor nr. 19,
                  545400-Sighişoara, Romania
                </p>

                <p>IBAN / EURO : RO30 BTRL 0270 4205 A501 88XX</p>
                <p>IBAN / RON: RO55 BTRL 0270 1205 A501 88XX</p>
              </div>
            </div>
          )}

          {selectRegion === "se" && (
            <div className="bank-transfer-container">
              <div className="">
                <p style={{ textAlign: "center" }}>Donate with:</p>
                <div>
                  <img
                    // className="flag-image"
                    src={qrCode}
                    alt="qr code sweden"
                  />
                </div>
              </div>

              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>Habo Missionförsamling Bankgiro 779-9455</p>
                <p>Swish 123 453 55 30 Mark Romanian ev project</p>
              </div>
            </div>
          )}

          {selectRegion === "de" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">

                <p>
                  Important! The intended purpose should be noted on the
                  transaction
                </p>

                <div>
                  <iframe src="https://smg.payrexx.com/de/pay?cid=622a4754&appview=1" allow="payment *" width="100%" height="800" id="payrexx-embed"></iframe>
                </div>
                <p>
                  For bank transfers please use the following account
                  information
                </p>

              </div>

              <div className="bank-transfer-content">
                <p>IBAN: DE37 7605 0101 0001 1284 48</p>
                <p>BIC: SSKNDE77XX</p>
                <p>HILFSVERK LEBENSBROT</p>
                <p>SPARKASSE NURNBERG </p>
              </div>
            </div>
          )}

          {selectRegion === "ch" && (
            <div className="bank-transfer-container">
              <div className="bank-transfer-title">
                <p>
                  For bank transfers please use the following account
                  information
                </p>
              </div>

              <div className="bank-transfer-content">
                <p>IBAN CH92 0900 0000 8004 2881 3</p>
                <p>
                  Kontoinhaber: Schweizerische Missionsgemeinschaft, 8404
                  Winterthur,
                </p>
                <p>Fundatia Metro Ministries Romania Projekt 494030</p>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <ColorButton autoFocus onClick={handleCloseDialog4}>
            Close
          </ColorButton>
        </DialogActions>
      </Dialog>
      <div className="footer-donations">
        <Footer />
      </div>
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Donation);

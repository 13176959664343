import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Reveal from "../../../utils/motion/reveal";
import { church1, church2, church3, church4 } from "../../../assets/churchnetworking";
import Footer from "../../Footer/Footer";


const ChurchNetworking = (props) => {
  const {t} = props;
  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${church1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">{t("ChurchNetworking.ChurchNetworking")}</span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item">{t("ChurchNetworking.ChurchNetworking")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <Reveal>
                <div className="section-image">
                  <img src={church2} alt="chruch networking image"/>
                </div>
              </Reveal>

              <div className="section-text">
                <Reveal>
                  <div className="section-text-title">
                    <h2>{t("ChurchNetworking.ChurchTitle")}</h2>
                  </div>
                </Reveal>
                <div className="section-text-p">
                  <Reveal>
                    <p>
                    {t("ChurchNetworking.ChurchDesc1")}
                    </p>
                  </Reveal>
                  <Reveal>
                    <p>
                    {t("ChurchNetworking.ChurchDesc2")}
                    </p>
                  </Reveal>
                  <Reveal>
                    <p>
                    {t("ChurchNetworking.ChurchDesc3")}
                    </p>
                  </Reveal>

                  <Reveal>
                        <div className="image-row">
                          <div className="image-col">
                            <img src={church4} alt="chruch networking image"/>
                          </div>
                          <div className="image-col">
                            <img src={church3} alt="chruch networking image"/>
                          </div>
                        </div>
                  </Reveal>

                  <Reveal>
                    <p>
                    {t("ChurchNetworking.ChurchDesc4")}
                    </p>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(ChurchNetworking);

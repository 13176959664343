import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "../styles/sighisoara.css";

import {
  sighisoaraPicture,
  sighisoaraPicture2,
  rhemaHouse,
  summercamp1,
  summercamp2,
  children1,
  tens1,
} from "../../assets/sighisoara";

import Footer from "../Footer/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {
  afterschool1,
  afterschool2,
  afterschool3,
  afterschool4,
} from "../../assets/afterschool";
import { bgshape } from "../../assets/shapes";
import Reveal from "../../utils/motion/reveal";

const Sighisoara = (props) => {
  const{t} = props;

  const gridItemStyle = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  return (
    <>
      <div className="page-container">
        {/* <img
          className="sighisoara-banner"
          src={sighisoaraPicture2}
          alt="sighisoara"
        /> */}

        <div
          className="sighisoara-banner"
          style={{
            backgroundImage: `url(${sighisoaraPicture2})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="row">
            <h1>
              <Reveal>
                <span className="color-big-heading">Sighișoara</span>
              </Reveal>
            </h1>
            <Reveal>
              <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">{t("Menu.Projects")}</li>
                <li className="breadcrumb-item">
                  <ArrowForwardIcon></ArrowForwardIcon>
                </li>
                <li className="breadcrumb-item ">Sighișoara</li>
              </ul>
            </Reveal>
          </div>
        </div>

        <div className="page-section">
          <div className="info-section">
            <Reveal>
              <div className="section-title">
                <h1>Sighișoara</h1>
              </div>
            </Reveal>

            <div className="section-content">
              <Reveal>
                <p>
                  {t("Sighisoara.SighisoaraDesc1")}
                </p>
              </Reveal>

              <Reveal>
                <p>
                {t("Sighisoara.SighisoaraDesc2")}
                </p>
              </Reveal>

              <Reveal>
                <p>
                {t("Sighisoara.SighisoaraDesc3")}
                </p>
              </Reveal>
            </div>
          </div>

          <div className="image-section">
            <img src={sighisoaraPicture} alt="sighisoara" />
          </div>
        </div>

        <div className="bg-shape">
          <img src={bgshape} />
        </div>

        <div className="rhema-house-section">
          <Reveal>
            <div className="rhema-house-title">
              <h1>{t("Sighisoara.RhemaHouseCenter")}</h1>
              <p>{t("Sighisoara.RhemaHouseCenterTitle")}</p>
            </div>
          </Reveal>

          <div className="flex-row-style">
            <Reveal>
              <div className="rhema-house-image">
                <img src={rhemaHouse} alt="sighisoara" />
              </div>
            </Reveal>
            <div className="section-content rhema-house-content-section">
              <Reveal>
                <p>
                  {t("Sighisoara.RhemaHouseDesc1")}
                </p>
              </Reveal>
              <Reveal>
                <p>
                {t("Sighisoara.RhemaHouseDesc2")}
                </p>
              </Reveal>
              <Reveal>
              <p>
                {t("Sighisoara.RhemaHouseDesc3")}
                </p>
              </Reveal>
              <Reveal>
              <p>
                {t("Sighisoara.RhemaHouseDesc4")}
                </p>
              </Reveal>
            </div>
          </div>
        </div>

        <div>
          {/* <div className="afterschool-image"></div> */}
          <div className="afterschool-program">
            <Reveal>
              <div className="afterschool-program-title">
                <h1>{t("Sighisoara.ProjectAndActivities")}</h1>
              </div>
            </Reveal>

            <Reveal>
              <div className="afterschool-program-title">
                <h1>{t("Sighisoara.AfterSchoolTitle")}</h1>
              </div>
            </Reveal>
            <div className="afterschool-program-details">
              <Reveal>
                <p>
                {t("Sighisoara.AfterSchoolDesc1")}
                </p>
              </Reveal>

              <Reveal>
                <p>
                {t("Sighisoara.AfterSchoolDesc2")}
                </p>
              </Reveal>

              <Reveal>
                <p>
                {t("Sighisoara.AfterSchoolDesc3")}
                </p>
              </Reveal>

              <Reveal>
                <p>
                {t("Sighisoara.AfterSchoolDesc4")}
                </p>
              </Reveal>
            </div>
            <Reveal>
              <section>
                <div className="afterschool-grid-images">
                  <div style={gridItemStyle(afterschool1)}></div>
                  <div style={gridItemStyle(afterschool2)}></div>
                  <div style={gridItemStyle(afterschool3)}></div>
                  <div style={gridItemStyle(afterschool4)}></div>
                </div>
              </section>
            </Reveal>

            <section className="afterschool-program-details">
              <Reveal>
                <p>
                {t("Sighisoara.AfterSchoolDesc5")}
                </p>
              </Reveal>
            </section>
          </div>
        </div>

        <section style={{ paddingBottom: "4rem" }}>
          <div className="sighisoara-section">
            <Reveal>
              <div className="afterschool-program-title">
                <h1>
                  {t("Sighisoara.ActivitiesTitle")}
                </h1>
              </div>
            </Reveal>

            <div className="text-and-image">
              <div className="afterschool-program-details">
                <Reveal>
                  <p>
                    {t("Sighisoara.ActivitiesDesc1")}
                  </p>

                  <p>
                  {t("Sighisoara.ActivitiesDesc2")}
                  </p>

                  <p>
                  {t("Sighisoara.ActivitiesDesc3")}
                  </p>
                </Reveal>
              </div>
              <div>
                <Reveal>
                  <div className="image-section-style"
                    style={{
                      backgroundImage: `url(${children1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "2%",
                    }}
                  ></div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <section style={{ paddingBottom: "4rem" }}>
          <div className="sighisoara-section">
            <Reveal>
              <div className="afterschool-program-title">
                <h1>{t("Sighisoara.Teens")}</h1>
              </div>
            </Reveal>

            <div className="text-and-image">
              <div>
                <Reveal>
                  <div className="image-section-style"
                    style={{
                      backgroundImage: `url(${tens1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "2%",
                    }}
                  ></div>
                </Reveal>
              </div>

              <div className="afterschool-program-details">
                <Reveal>
                  <p>
                    {t("Sighisoara.TeensDesc")}
                  </p>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        {/* Empowering teens for a bright future */}

        <section>
          <div className="sighisoara-section">
            <Reveal>
              <div className="afterschool-program-title">
                <h1>
                {t("Sighisoara.SummerCamps")}
                </h1>
              </div>
            </Reveal>

            <div className="text-and-image">
              <div className="afterschool-program-details">
                <Reveal>
                  <p>
                  {t("Sighisoara.SummerCampsDesc1")}
                  </p>

                  <p>
                  {t("Sighisoara.SummerCampsDesc2")}
                  </p>
                </Reveal>
              </div>
              <div>
                <Reveal>
                  <div
                    className="image-section-style"
                    style={{
                      backgroundImage: `url(${summercamp1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "2%",
                    }}
                  ></div>
                </Reveal>
              </div>
            </div>
            <div className="text-and-image">
              <div>
                <Reveal>
                  <div
                    className="image-section-style"
                    style={{
                      backgroundImage: `url(${summercamp2})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "2%",
                    }}
                  ></div>
                </Reveal>
              </div>
              <div className="afterschool-program-details">
                <Reveal>
                  <p>
                  {t("Sighisoara.SummerCampsDesc3")}
                  </p>
                </Reveal>
              </div>
            </div>

            <div className="text-and-image">
              <div className="afterschool-program-details">
                <Reveal>
                  <p>
                  {t("Sighisoara.SummerCampsDesc4")}
                  </p>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Sighisoara);

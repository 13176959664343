import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../Footer/Footer";
import { out1, out2, out3, out5, out6 } from "../../../assets/outreach";

const Outreach = (props) => {
  const { t } = props;

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${out2})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Outreach.OutreachTitle")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">
                    {t("Outreach.OutreachTitle")}
                  </li>
                </ul>
              </Reveal>
            </div>
          </div>

          <section className="section-container">
            <div className="section-row">
              <div className="section-col1">
                <div>
                  <Reveal>
                    {/* <div className="section-image">
                      <img src={after2} alt="afterschool" />
                    </div> */}
                  </Reveal>

                  <div className="section-text">
                    <Reveal>
                      <div className="section-text-title">
                        <h2>{t("Outreach.SummerCampsTitle")}</h2>
                      </div>
                    </Reveal>

                    <Reveal>
                      <div className="section-text-p">
                        <p>{t("Outreach.SummerDesc1")}</p>

                        <div className="image-row">
                          <div className="image-col">
                            <img src={out1} />
                          </div>
                          <div className="image-col">
                            <img src={out5} />
                          </div>
                        </div>

                        <p>{t("Outreach.SummerDesc2")}</p>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-container">
            <div className="section-row">
              <div className="section-col1">
                <div>
                  <Reveal>
                    {/* <div className="section-image">
                      <img src={after2} alt="afterschool" />
                    </div> */}
                  </Reveal>

                  <div className="section-text">
                    <Reveal>
                      <div className="section-text-title">
                        <h2>{t("Outreach.CampsTitle")}</h2>
                      </div>
                    </Reveal>

                    <Reveal>
                      <div className="section-text-p">
                        <p>{t("Outreach.CampsDesc1")}</p>
                        <p>{t("Outreach.CampsDesc2")}</p>
                        <p>{t("Outreach.CampsDesc3")}</p>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-container">
            <div className="section-row">
              <div className="section-col1">
                <div>
                  <Reveal>
                    {/* <div className="section-image">
                      <img src={after2} alt="afterschool" />
                    </div> */}
                  </Reveal>

                  <div className="section-text">
                    <Reveal>
                      <div className="section-text-title">
                        <h2>{t("Outreach.WarmFeetTitle")}</h2>
                      </div>
                    </Reveal>

                    <Reveal>
                      <div className="section-text-p">
                        <p>{t("Outreach.WarmDesc1")}</p>
                        <p>{t("Outreach.WarmDesc2")}</p>
                        <p>{t("Outreach.WarmDesc3")}</p>
                        <div className="image-row">
                          <div className="image-col">
                            <img src={out3} />
                          </div>
                          <div className="image-col">
                            <img src={out6} />
                          </div>
                        </div>
                        <p>{t("Outreach.WarmDesc4")}</p>
                        <p>{t("Outreach.WarmDesc5")}</p>
                        <p>{t("Outreach.WarmDesc6")}</p>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Outreach);

import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { out1 } from "../../../assets/outreach";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Link } from "react-router-dom";

import "../../styles/voluntering.css";
import Footer from "../../Footer/Footer";
import ColorButton from "../../../utils/custom/colorButton";

const Voluntering = (props) => {
  const { t } = props;
  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${out1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Menu.Voluntering")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.Voluntering")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.Voluntering")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
          <section className="section-container">
            <div className="contact-container">
              <Reveal>
                <div className="contact-container-title">
                  <h2>{t("Voluntering.VolunteringTitle")}</h2>
                  <p>{t("Voluntering.VolunteringSubTitle")}</p>
                </div>
              </Reveal>
            </div>
            <div className="section-row">
              <div className="contact_item vol-width">
                <div className="voluntering-details">
                  <p>{t("Voluntering.VolunteringDesc1")}</p>
                  <p>{t("Voluntering.VolunteringDesc2")}</p>
                  <h2>{t("Voluntering.VolunteringSecondTitle")}</h2>
                  <div>
                    <p>
                      <strong>{t("Voluntering.PersonalGrowth")}</strong> {t("Voluntering.PersonalGrowthDesc")}
                    </p>
                    <p>
                      <strong>{t("Voluntering.CareerAdvancement")}</strong> {t("Voluntering.CareerAdvancementDesc")}
                    </p>
                    <p>
                      <strong>{t("Voluntering.CommunityImpact")}</strong> {t("Voluntering.CommunityImpactDesc")}
                    </p>
                    <p>
                      <strong>{t("Voluntering.Support")}</strong> {t("Voluntering.SupportDesc")}
                    </p>
                    <p>
                      <strong>{t("Voluntering.Life")}</strong> {t("Voluntering.LifeDesc")}
                    </p>
                  </div>
                  <Link to="/contact">
                    <ColorButton variant="contained">
                      {t("Internship.ContactUs")}
                    </ColorButton>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Voluntering);

import React,{useEffect, useRef} from "react";

import {motion, useInView, useAnimation} from "framer-motion";

const Reveal = (props) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect( () => {
        if(isInView) {
            mainControls.start("visible");
            slideControls.start("visible");
        }
    }, [isInView]);

    return (
        //style={{position:"relative", width: "fit-content", overflow:"hidden"}}
        <div ref={ref} >
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: 75},
                    visible: {opacity: 1, y: 0}
                }}
                initial="hidden"
                animate={mainControls}
                transition={{duration: 1}}
            >
                {props.children}
            </motion.div>
            {/* <motion.div
                variants={{
                    hidden: {left: 0},
                    visible: {left: "100%"}
                }}
                initial="hidden"
                animate={slideControls}
                transition={{ duration: 0.5, ease: "easeIn"}}
                style = {{
                    position: "absolute",
                    top: 4,
                    bottom: 4,
                    left: 0,
                    right:0,
                    background: "black",
                    zIndex: 20,
                }}
            /> */}
        </div>
    );
};

export default Reveal;

export const countryList = [
  {
    label: "Europe",
    value: "eu",
    flag_icon:
      "https://purecatamphetamine.github.io/country-flag-icons/3x2/EU.svg",
  },
  {
    label: "USA",
    value: "us",
    flag_icon:
      "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
  },
  {
    label: "Romania",
    value: "ro",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/RO.svg",
  },
  {
    label: "Sweden",
    value: "se",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg",
  },
  {
    label: "Germany",
    value: "de",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg",
  },
  {
    label: "Switzerland",
    value: "ch",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg",
  },
];

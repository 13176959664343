export const countryList = [
  {
    label: "English",
    value: "en",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg",
  },
  {
    label: "Română",
    value: "ro",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/RO.svg",
  },
  {
    label: "Svenska",
    value: "se",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg",
  },
  {
    label: "Deutsch",
    value: "de",
    flag_icon:
      "http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg",
  },
];

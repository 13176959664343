import React from "react";
import { rondola, rondola4, rondola5 } from "../../../assets";
import {
  poza1,
  poza2,
  poza3,
  poza4,
  poza5,
  poza6,
  poza7,
  poza8,
  poza10,
  poza11,
  poza12,
} from "../../../assets/news-images/warm feet 2023";
import {
  poza13,
  poza14,
  poza15,
  poza16,
  poza17,
  poza18,
} from "../../../assets/news-images/warm feet 2023";
import {
  poza19,
  poza20,
  poza21,
  poza22,
  poza23,
  poza24,
  poza25,
  poza26,
  poza27,
  poza28,
  poza29,
  poza30,
  singlePoza,
  singlePoza2,
} from "../../../assets/news-images/warm feet 2023";

import ImageSlider from "../../../utils/image-slider/image-slider";
import Reveal from "../../../utils/motion/reveal";
import Footer from "../../Footer/Footer";

const NewsPage1 = () => {
  const images = [
    poza1,
    poza2,
    poza3,
    poza4,
    poza5,
    poza6,
    poza7,
    poza8,
    poza10,
    poza11,
    poza12,
  ];
  const images2 = [poza13, poza14, poza15, poza16, poza17, poza18];
  const images3 = [
    poza19,
    poza20,
    poza21,
    poza22,
    poza23,
    poza24,
    poza25,
    poza26,
    poza27,
    poza28,
    poza29,
    poza30,
  ];

  return (
    <div>
      <div className="page-container">
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${rondola})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="article">
            <div className="row-article">
              <h1>
                <span class="article-title">Warm Feet 2023</span>
              </h1>
              <div className="article-info">
                <div className="article-data">
                  <span>By Ligia Pintican</span>
                </div>
                <div className="article-data">FEBRUARY 6, 2024</div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-container pd-t-40">
          <div className="article-content-row article-width">
            <div className="section-col1">
              <div className="dialog-container-content">
                <p>
                  Once again, the project was a blessing for thousands of
                  children and families in need, as well was bringing comfort
                  and help for those living in difficult circumstances around
                  us. When wet and cold outside to be warm and able to go out
                  and play, go to school or just get wood for the heat its
                  really great to have good shoes and warm cloth.
                </p>
              </div>

              <div>
                <ImageSlider images={images} />
              </div>
              <div className="dialog-container-content">
                <p>
                  Beside practical help at Warm Feet we always do a lot of fun
                  thing with the children, giving them a joyful time with many
                  games and also some exciting drama moments to learn about the
                  memorable unique story of Jesus birthday.
                </p>

                <p>
                  The message this year was that at Christmas, we celebrate
                  Jesus, not only being born but being the One who brough
                  preciouse gifts like: salvation from our sinful life, hope,
                  and possibility to a new life. All those who visited Jesus at
                  his birthplace celebrated Him: shepherds, the 3 kings, as well
                  as the angels. We still wonder how much today time people
                  think of those unprecedent gifts when Christmas comes. Do
                  people really know what Christasm is about?
                </p>
              </div>

              <div>
                <ImageSlider images={images2} />
              </div>

              <div className="dialog-container-content">
                <p>
                  The project reached out to 18 villages and we met and blessed
                  over 3500 people Thanks to the committed volunteers from
                  Switzerland , Belgium and Romania, we could serve and help so
                  many. Besides our direct outreaches, we also blessed other
                  locations in January together with our partners, friend's
                  organizations, or churches so even more people could receive
                  help.
                </p>
              </div>

              <div >
                <img className="article-image-style" src={singlePoza} alt={"team"} />
              </div>

              <div className="dialog-container-content">
                <p>
                  In the Tiglarie community, a very poor part of Dumbraveni
                  little town, we visited families to invite them out along the
                  one street village. During children program one little boy was
                  freezing in his summery T-shirt and pajamas type pants, with
                  no socks or boots on his feet. Lidia noticed the child's
                  little body shaking, so she took the boy by the hand to one of
                  our volunteer's named Krista, whom she knew could look for
                  some warm clothing. It is not always certain that we have all
                  the needed clothes in all sizes, but this time there was a
                  sack in one of the vans that had everything the child needed
                  in the right size: pants, pullover, jacket, as well we could
                  give him socks and boots. Usually, it is hard to help one
                  child before all the others, as everyone is in such great
                  need. But this time, all the children around him were happy to
                  see him warming up and able to participate in the program.
                  Somebody up there, knew to set it up especially for this
                  little boy who needed.
                </p>

                <p>
                  It is so good to be able to help, to make a difference in
                  times of need.
                </p>

                <div>
                  <ImageSlider images={images3} />
                </div>

                <p>
                  Next time, welcome to join with us, it is a joy to give,
                  share, be together and make a difference!
                </p>
              </div>
            </div>

            <div className="section-col1">
                <div className="sidebar_widget has_border mb-40">
                    <div className="sidebar_title">
                        <h4 class="sidebar_title_text has_border"><span></span>Latest News</h4>
                    </div>

                    <div className="feed_widget-content">
                        <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div>
                        <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola4} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spring update 2024</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th May 2024
                                </a>
                            </div>
                        </div>
                        {/* <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div> */}
                        <div class="single_feed_widget">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Warm Feet 2023</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>20 January 2024
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default NewsPage1;

import React from 'react'

import Homepage from './Homepage';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import '../styles/home.css'
import Vision from './Vision';
import NewsFeed from './NewsFeed';
import DonationsHome from './DonationsHome';
import Become from './Become';
import Footer from '../Footer/Footer';

import {n1,n2,n3} from '../../assets'
import Slider from 'react-slick';

const Home = () => {
  return (

    <div className='container'>
      <Homepage />
      <DonationsHome/>
      <Vision />
      <NewsFeed />
      <Become />
      <Footer />
    </div>

  )
}

const enhance = compose(
    withTranslation('translation')
)

export default enhance(Home)
import React from "react";
import "./Balloon.css";

const Balloon = ({ id,color, left, animation, onAnimationEnd }) => {
  
    const handleAnimationEnd = () => {
        onAnimationEnd();
    };
  
    return (
    <div
        id={id}
        className="balloon"
        style={{ backgroundColor: color, color:color, left: `${left}%`,  animationDuration: `${animation}s`}}
        onAnimationEnd={handleAnimationEnd}
    ></div>
  );
};

export default Balloon;

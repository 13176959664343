import React, { useState, useEffect } from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { motion } from "framer-motion";

import "../styles/menu.css";
import Reveal from "../../utils/motion/reveal";

const Menu = (props) => {
  const { t } = props;

  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [activeDropdownProjects, setActiveDropdownProjects] = useState(false);
  const [activeDropdownAbout, setActiveDropdownAbout] = useState(false);
  const [activeDropdownHow, setActiveDropdownHow] = useState(false);
  const [activeDropdownGet, setActiveDropdownGet] = useState(false);

  const handleProjects = () => {
    setActiveDropdownProjects(!activeDropdownProjects);
    setActiveDropdownAbout(false);
    setActiveDropdownHow(false);
    setActiveDropdownGet(false);
  };

  const handleAbout = () => {
    setActiveDropdownProjects(false);
    setActiveDropdownAbout(!activeDropdownAbout);
    setActiveDropdownHow(false);
    setActiveDropdownGet(false);
  };

  const handleHow = () => {
    setActiveDropdownProjects(false);
    setActiveDropdownAbout(false);
    setActiveDropdownHow(!activeDropdownHow);
    setActiveDropdownGet(false);
  };

  const handleGet = () => {
    setActiveDropdownProjects(false);
    setActiveDropdownAbout(false);
    setActiveDropdownHow(false);
    setActiveDropdownGet(!activeDropdownGet);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the pixel value to your preference (768px is common for mobile)
    };

    // Run on initial load and whenever the window is resized
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <div>
          <div className="hamburger" onClick={toggleMenu}>
            ☰
          </div>
          <div className={`menu-list-links ${menuOpen ? "active" : ""}`}>
            <div className="menu-list-item">
              <a className="hover-underline-animation" onClick={handleProjects}>
                {t("Menu.Projects")}{" "}
                <div className="showArrow">
                  {activeDropdownProjects ? (
                    <ArrowDownwardIcon></ArrowDownwardIcon>
                  ) : (
                    <ArrowForwardIcon></ArrowForwardIcon>
                  )}
                </div>
              </a>
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: activeDropdownProjects ? 250 : 0 }}
                transition={{ duration: 1 }} // Animation duration of 1 second
                style={{ overflow: "hidden" }}
              >
                <ul className={"dropdown-menu2"}>
                  <li>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <a href="#/sighisoara" className="dropdown-item" onClick={toggleMenu}>
                        Sighişoara
                      </a>
                    </motion.div>
                  </li>
                  <li>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }} // Animation duration of 1 second
                    >
                      <a href="#/albesti" className="dropdown-item" onClick={toggleMenu}>
                        Albeşti
                      </a>
                    </motion.div>
                  </li>
                  <li>
                    <motion.div
                      initial={{ opacity: 0 }} // Initial position
                      animate={{ opacity: 1 }} // Moves down 200px
                      transition={{ duration: 1 }} // Animation duration of 1 second
                    >
                      <a href="#/house-of-joy" className="dropdown-item" onClick={toggleMenu}>
                        {t("Menu.HouseOfJoy")}
                      </a>
                    </motion.div>
                  </li>
                  <li>
                    <motion.div
                      initial={{ opacity: 0 }} // Initial position
                      animate={{ opacity: 1 }} // Moves down 200px
                      transition={{ duration: 1 }} // Animation duration of 1 second
                    >
                      <a href="#/daycare" className="dropdown-item" onClick={toggleMenu}>
                        {t("Menu.DaycareCenter")}
                      </a>
                    </motion.div>
                  </li>
                  <li>
                    <motion.div
                      initial={{ opacity: 0 }} // Initial position
                      animate={{ opacity: 1 }} // Moves down 200px
                      transition={{ duration: 1 }} // Animation duration of 1 second
                    >
                      <a href="#/hoghilag" className="dropdown-item" onClick={toggleMenu}>
                        Hoghilag
                      </a>
                    </motion.div>
                  </li>
                </ul>
              </motion.div>
            </div>
            <div className="menu-list-item">
              <a className="hover-underline-animation" onClick={handleAbout}>
                {t("Menu.AboutUs")}{" "}
                <div className="showArrow">
                  {activeDropdownAbout ? (
                    <ArrowDownwardIcon></ArrowDownwardIcon>
                  ) : (
                    <ArrowForwardIcon></ArrowForwardIcon>
                  )}
                </div>
              </a>
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: activeDropdownAbout ? 190 : 0 }}
                transition={{ duration: 1 }} // Animation duration of 1 second
                style={{ overflow: "hidden" }}
              >
                <ul className={"dropdown-menu2"}>
                  <li>
                    <a href="#/history" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.History")}
                    </a>
                  </li>
                  <li>
                    <a href="#/vision" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Vision")}
                    </a>
                  </li>
                  <li>
                    <a href="#/team" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Team")}
                    </a>
                  </li>
                  <li>
                    <a href="#/contact" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Contacts")}
                    </a>
                  </li>
                </ul>
              </motion.div>
            </div>

            <div className="menu-list-item">
              <a className="hover-underline-animation" onClick={handleHow}>
                {t("Menu.HowWeWork")}{" "}
                <div className="showArrow">
                  {activeDropdownHow ? (
                    <ArrowDownwardIcon></ArrowDownwardIcon>
                  ) : (
                    <ArrowForwardIcon></ArrowForwardIcon>
                  )}
                </div>
              </a>
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: activeDropdownHow ? 290 : 0 }}
                transition={{ duration: 1 }} // Animation duration of 1 second
                style={{ overflow: "hidden" }}
              >
                <ul className="dropdown-menu2">
                  <li>
                    <a
                      href="#/education/kindergarden"
                      className="dropdown-item"
                      onClick={toggleMenu}
                    >
                      {t("Menu.Kindergarden")}
                    </a>
                  </li>

                  <li>
                    <a href="#/education/afterschool" className="dropdown-item"onClick={toggleMenu}> 
                      {t("Menu.AfterSchool")}
                    </a>
                  </li>

                  <li>
                    <a href="#/social-services" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.SocialServices")}
                    </a>
                  </li>
                  <li>
                    <a href="#/outreach" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Outreaches")}
                    </a>
                  </li>
                  <li>
                    <a href="#/sunday-school" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.SundaySchool")}
                    </a>
                  </li>
                  <li>
                    <a href="#/church-networking" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.ChurchNetworking")}
                    </a>
                  </li>
                </ul>
              </motion.div>
            </div>
            <div className="menu-list-item">
              <a className="hover-underline-animation" onClick={handleGet}>
                {t("Menu.GetInvolved")}{" "}
                {activeDropdownGet ? (
                  <ArrowDownwardIcon></ArrowDownwardIcon>
                ) : (
                  <ArrowForwardIcon></ArrowForwardIcon>
                )}
              </a>
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: activeDropdownGet ? 250 : 0 }}
                transition={{ duration: 1 }} // Animation duration of 1 second
                style={{ overflow: "hidden" }}
              >
                <ul className={"dropdown-menu2"} onClick={toggleMenu}>
                  <li>
                    <a href="#/voluntering" className="dropdown-item">
                      {t("Menu.Voluntering")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Sponsorship")}
                    </a>
                  </li> */}
                  <li>
                    <a href="#/building" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.BuildingTeams")}
                    </a>
                  </li>
                  <li>
                    <a href="#/internship" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Internship")}
                    </a>
                  </li>
                  <li>
                    <a href="#/donation" className="dropdown-item" onClick={toggleMenu}>
                      {t("Menu.Donations")}
                    </a>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      ) : (
        <div className="menu-list-links">
          <div className="menu-list-item">
            <a className="hover-underline-animation">{t("Menu.Projects")}</a>
            <ul className={"dropdown-menu"}>
              <li>
                <a href="#/sighisoara" className="dropdown-item">
                  Sighişoara
                </a>
              </li>
              <li>
                <a href="#/albesti" className="dropdown-item">
                  Albeşti
                </a>
              </li>
              <li>
                <a href="#/house-of-joy" className="dropdown-item">
                  {t("Menu.HouseOfJoy")}
                </a>
              </li>
              <li>
                <a href="#/daycare" className="dropdown-item">
                  {t("Menu.DaycareCenter")}
                </a>
              </li>
              <li>
                <a href="#/hoghilag" className="dropdown-item">
                  Hoghilag
                </a>
              </li>
            </ul>
          </div>
          <div className="menu-list-item">
            <a className="hover-underline-animation">{t("Menu.AboutUs")}</a>
            <ul className={"dropdown-menu"}>
              <li>
                <a href="#/history" className="dropdown-item">
                  {t("Menu.History")}
                </a>
              </li>
              <li>
                <a href="#/vision" className="dropdown-item">
                  {t("Menu.Vision")}
                </a>
              </li>
              <li>
                <a href="#/team" className="dropdown-item">
                  {t("Menu.Team")}
                </a>
              </li>
              <li>
                <a href="#/contact" className="dropdown-item">
                  {t("Menu.Contacts")}
                </a>
              </li>
            </ul>
          </div>
          <div className="menu-list-item">
            <a className="hover-underline-animation">{t("Menu.HowWeWork")}</a>
            <ul className="dropdown-menu">
              <li className="dropdown-submenu">
                <a className="dropdown-item">{t("Menu.Education")}</a>
                <ul className="submenu">
                  <li>
                    <a
                      href="#/education/kindergarden"
                      className="dropdown-item"
                    >
                      {t("Menu.Kindergarden")}
                    </a>
                  </li>
                  <li>
                    <a href="#/education/afterschool" className="dropdown-item">
                      {t("Menu.AfterSchool")}
                    </a>
                  </li>
                </ul>{" "}
              </li>

              <li>
                <a href="#/social-services" className="dropdown-item">
                  {t("Menu.SocialServices")}
                </a>
              </li>
              <li>
                <a href="#/outreach" className="dropdown-item">
                  {t("Menu.Outreaches")}
                </a>
              </li>
              <li>
                <a href="#/sunday-school" className="dropdown-item">
                  {t("Menu.SundaySchool")}
                </a>
              </li>
              <li>
                <a href="#/church-networking" className="dropdown-item">
                  {t("Menu.ChurchNetworking")}
                </a>
              </li>
            </ul>
          </div>
          <div className="menu-list-item">
            <a className="hover-underline-animation">{t("Menu.GetInvolved")}</a>
            <ul className={"dropdown-menu"}>
              <li>
                <a href="#/voluntering" className="dropdown-item">
                  {t("Menu.Voluntering")}
                </a>
              </li>
              {/* <li>
                <a href="#" className="dropdown-item">
                  {t("Menu.Sponsorship")}
                </a>
              </li> */}
              <li>
                <a href="#/building" className="dropdown-item">
                  {t("Menu.BuildingTeams")}
                </a>
              </li>
              <li>
                <a href="#/internship" className="dropdown-item">
                  {t("Menu.Internship")}
                </a>
              </li>
              <li>
                <a href="#/donation" className="dropdown-item">
                  {t("Menu.Donations")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Menu);

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Box
} from "@mui/material";
import React from "react";
import ColorButton from "../../utils/custom/colorButton";
import { Link } from 'react-router-dom'

const NewsCard = (props) => {
  const { title, image, description, linkDestination } = props;

  return (
    <div>
      <Box
        sx={{
          transition: "transform 0.3s, box-shadow 0.3s",
          "&:hover": {
            transform: "translateY(-10px)", // Moves the card up by 10px
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)", // Adds shadow on hover
          },
        }}
      >
        <Card
          sx={{
            width: 400,
            height: 550,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardHeader
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "380px",
            }}
            title={title}
          />
          <CardMedia component="img" height="200" image={image} />
          <CardContent>
            <p style={{ fontFamily: "Inter,sans-serif", lineHeight: 1.45455 }}>
              {description}
            </p>
          </CardContent>
          <CardActions disableSpacing sx={{ mt: "auto" }}>
            <Link to={linkDestination}>
            <ColorButton size="small">
              Read more
            </ColorButton>
            </Link>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
};

export default NewsCard;

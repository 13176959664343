import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { albesti, albesti2, albesti3, albesti5 } from "../../../assets/albesti";
import {
  albestigrip1,
  albestigrip2,
  albestigrip3,
  albestigrip4,
} from "../../../assets/albesti";

import "../../styles/albesti.css";

import Footer from "../../Footer/Footer";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Albesti = (props) => {
  const { t } = props;
  const gridItemStyle = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  });

  return (
    <>
      <div className="page-container">
        <div
          className="albesti-banner"
          style={{
            backgroundImage: `url(${albesti})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "145px 0",
          }}
        >
          <div className="row">
            <h1>
              <Reveal>
                <span className="color-big-heading">
                  {t("Albesti.Albesti")}
                </span>
              </Reveal>
            </h1>
            <Reveal>
              <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">Projects</li>
                <li className="breadcrumb-item">
                  <ArrowForwardIcon></ArrowForwardIcon>
                </li>
                <li className="breadcrumb-item ">Albeşti</li>
              </ul>
            </Reveal>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2 className="align-text-center">
                        {t("Albesti.Albesti")}
                      </h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>{t("Albesti.AlbestiDesc1")}</p>

                      <Reveal>
                        <div className="section-image">
                          <img src={albesti2} alt="sighisoara" />
                        </div>
                      </Reveal>

                      <p>{t("Albesti.AlbestiDesc2")}</p>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            {/* <div className="section-col"></div> */}
          </div>
        </section>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={albesti5} />
                  </div>
                </Reveal>
                <div className="section-text">
                  <Reveal>
                    <div className="section-text-p pad-top5 pad-bottom3 ">
                      <p>{t("Albesti.AlbestiDesc3")}</p>
                      <p>{t("Albesti.AlbestiDesc4")}</p>
                      <p>{t("Albesti.AlbestiDesc5")}</p>
                      <Reveal>
                        <p>{t("Albesti.AlbestiDesc6")}</p>
                      </Reveal>

                      <Reveal>
                        <p>{t("Albesti.AlbestiDesc7")}</p>
                      </Reveal>

                      <Reveal>
                        <p>{t("Albesti.AlbestiDesc8")}</p>
                      </Reveal>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            <div></div>
          </div>

          <Reveal>
            <section>
              <div className="albesti-grid-images">
                <div style={gridItemStyle(albestigrip1)}></div>
                <div style={gridItemStyle(albestigrip2)}></div>
                <div style={gridItemStyle(albestigrip3)}></div>
                <div style={gridItemStyle(albestigrip4)}></div>
              </div>
            </section>
          </Reveal>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Albesti);

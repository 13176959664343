import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Footer from "../../Footer/Footer";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  sunday1,
  sunday2,
  sunday3,
  sunday4,
  sunday5,
  sunday6,
} from "../../../assets/sunday";

const SundaySchool = (props) => {
  const { t } = props;

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${sunday3})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Sunday.SundayTitle")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">
                    {t("Sunday.SundayTitle")}
                  </li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                {/* <Reveal>
                            <div className="section-image">
                                <img src={sunday1}/>
                            </div>
                        </Reveal> */}

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Sunday.SundaySchoolTitle")}</h2>
                    </div>
                  </Reveal>

                  <div className="section-text-p">
                    <Reveal>
                      <p>{t("Sunday.SundayDesc1")}</p>
                      <p>{t("Sunday.SundayDesc2")}</p>

                      <p>{t("Sunday.SundayDesc3")}</p>
                    </Reveal>

                    <Reveal>
                      <p>{t("Sunday.SundayDesc4")}</p>

                      <p>{t("Sunday.SundayDesc5")}</p>

                      <p>{t("Sunday.SundayDesc6")}</p>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={sunday6} />
                  </div>
                </Reveal>

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Sunday.TeenagersTitle")}</h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>{t("Sunday.TeenDesc1")}</p>

                      <p>{t("Sunday.TeenDesc2")}</p>

                      <p>{t("Sunday.TeenDesc3")}</p>

                      <p>{t("Sunday.TeenDesc4")}</p>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(SundaySchool);

import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ColorButton from "../../utils/custom/colorButton";

import "../styles/homepage-donations.css";

import { charity, pray, hope, human } from "../../assets";
import Reveal from "../../utils/motion/reveal";
import { Link } from "react-router-dom";

const DonationsHome = (props) => {
  const {t} = props;

  return (
    <div className="donations-container">
      <div className="donations-row">
        <div>
          <Reveal>
            <h2 className="section-title">{t("DonationHome.DonationTitle")}</h2>
          </Reveal>
        </div>
      </div>
      <Reveal>
        <div className="donations-row">
          <div className="donations-col">
            <div className="service-icon">
              <img src={hope} className="icon-style" alt="hopeIcon" />
            </div>
            <div className="service-content">
              <h4 className="service-title">{t("DonationHome.Hope")}</h4>
              <p className="service-text">
                {t("DonationHome.HopeDesc")}
              </p>
            </div>
          </div>
          <div className="donations-col">
            <div className="service-icon">
              <img src={pray} className="icon-style" alt="prayIcon" />
            </div>
            <div className="service-content">
              <h4 className="service-title">{t("DonationHome.Prayers")}</h4>
              <p className="service-text">{t("DonationHome.PrayersDesc")}</p>
            </div>
          </div>
          <div className="donations-col">
            <div className="service-icon">
              <img src={human} className="icon-style" alt="peaceIcon" />
            </div>
            <div className="service-content">
              <h4 className="service-title">{t("DonationHome.Peace")}</h4>
              <p className="service-text">
                {t("DonationHome.PeaceDesc")}
              </p>
            </div>
          </div>
          <div className="donations-col">
            <div className="service-icon">
              <img src={charity} className="icon-style" alt="charityIcon" />
            </div>
            <div className="service-content">
              <h4 className="service-title">{t("DonationHome.Donation")}</h4>
              <p className="service-text">
              {t("DonationHome.DonationDesc")}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="donation-button">
          <Link to="donation">
            <ColorButton variant="contained">{t("DonationHome.DonateButton")}</ColorButton>
          </Link>
        </div> */}
      </Reveal>
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(DonationsHome);

import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import {
  after1,
  after2,
  after3,
  after4,
  after5,
  after6,
  after7,
  after8,
  after9,
  afterschool2,
} from "../../../assets/afterschool";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../Footer/Footer";

const AfterSchool = (props) => {
  const {t} = props;

  return (
    <>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${after1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">{t("Afterschool.AfterSchoolTitle")}</span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.Education")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Afterschool.AfterSchoolTitle")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={after2} alt="afterschool" />
                  </div>
                </Reveal>

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Afterschool.AfterSchoolTitlePage")}</h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>
                        {t("Afterschool.AfterDesc1")}
                      </p>
                      <p>
                        {t("Afterschool.AfterDesc2")}
                      </p>
                      <p>
                        {t("Afterschool.AfterDesc3")}
                      </p>

                      <Reveal>
                        <div className="image-row">
                          <div className="image-col">
                            <img src={after4} />
                          </div>
                          <div className="image-col">
                            <img src={after6} />
                          </div>
                        </div>
                      </Reveal>

                      <p>
                        {t("Afterschool.AfterDesc4")}
                      </p>

                      <Reveal>
                        <div className="image-row">
                          <div className="image-col">
                            <img src={after7} />
                          </div>
                          <div className="image-col">
                            <img src={after9} />
                          </div>
                        </div>
                      </Reveal>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(AfterSchool);

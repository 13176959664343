import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";

import "../styles/become.css";

import { internship, volunter, sponsor } from "../../assets";
import Reveal from "../../utils/motion/reveal";

import { Link } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  width: "150px",
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
}));

const Become = (props) => {
  const {t} = props;

  return (
    <div className="offer-container">
      <div className="row-container">
        <Reveal>
          <h2 className="section-title">{t("Become.BecomeTitle")}</h2>
        </Reveal>
      </div>

      <Reveal>
        <div className="row-container">
          <div >
            <div className="blog-content">
              <div className="blog-img">
                <img className="image" src={volunter} alt="volunterPic" />
              </div>
              <div className="blog-inner-content">
                <h3 className="blog-title">{t("Become.BecomeVolunteer")}</h3>
                <div>
                <p className="blog-desc">
                {t("Become.VolDesc1")}
                </p>
                <p className="blog-desc">{t("Become.VolDesc2")}</p>
                <p className="blog-desc">
                “{t("Become.VolDesc3")}” <b>Mahatma Gandhi</b>
                </p>
                </div>
                {/* <p className="blog-desc">“Be the
                  change you wish to see in the world” Mahatma Gandhi</p> */}
                <Link to="voluntering">
                <ColorButton>{t("Become.ButtonJoin")}</ColorButton>
              </Link>
              </div>
            </div>
          </div>

          <div >
            <div className="blog-img">
              <img className="image" src={internship} />
            </div>
            <div className="blog-inner-content">
              <h3 className="blog-title">{t("Become.BecomeInter")}</h3>
              <p className="blog-desc">
                  {t("Become.InterDesc")}
              </p>
              <Link to="internship">
                <ColorButton>{t("Become.ButtonJoin")}</ColorButton>
              </Link>
            </div>
          </div>

          <div>
            <div className="blog-img">
              <img className="image" src={sponsor} alt="sponsorshipPic" />
            </div>
            <div className="blog-inner-content">
              <h3 className="blog-title">{t("Become.BecomeSponsor")}</h3>
              <p className="blog-desc">
              {t("Become.SponsorDesc")}
              </p>
              <Link to="donation">
                <ColorButton>{t("Become.ButtonJoin")}</ColorButton>
              </Link>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Become);

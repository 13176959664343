import React, { useState, useEffect } from "react";
import { logo, logo2, logo3 } from "../../assets";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';


import "../styles/navbar.css";
import { Link } from "react-router-dom";

import LanguageSelector from "./LanguageSelector";
import Menu from "./Menu";


 
const Navbar = (props) => {
  const { t, i18n } = props;

  const [colorChange, setColorChange] = useState(false);

  const location = useLocation();
  
  useEffect(()=> {
    if (location.pathname !== "/") {
      setColorChange(true);
    }
    if (location.pathname === "/") {
      setColorChange(false);
    }
  },[location]);

  const changeNavbarColor = () => {
    if (location.pathname === "/") {
      if (window.scrollY >= 80) {
        setColorChange(true);
      } else {
        setColorChange(false);
      }
    } else {
      setColorChange(true);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <nav className={colorChange ? "navbar colorChange" : "navbar"}>
        <div className="navbar-container">
          <div className="navbar-logo" onClick={() => window.scrollTo(0, 0)}>
            <Link to="/">
              <img className="logo" src={logo3} alt="logo" />
            </Link>
            
            {/* <Link to="/">
              <h1 className="logo-name">Metro Ministries Romania</h1>
            </Link> */}
          </div>
        </div>

        <div className="navbar-menu-container">
          
          <Menu />

          <LanguageSelector colorChange={colorChange}/>
          
        </div>
      </nav>
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Navbar);

import React from 'react'
import { kinder1,kinder2,kinder3,kinder4,kinder5,kinder6,kinder7,kinder8, kinder9 } from "../../../assets/sighisoara";

import "../../styles/sighisoara.css";
import Masonry from 'react-responsive-masonry';

const PhotosAlbum = () => {

  return (
        <Masonry columnsCount={3} gutter="10px">
            <img className='album-image' src={kinder1} alt="kindergarden" /> 
            {/* <img className='album-image' src={kinder3} alt="kindergarden" /> */}
            <img className='album-image' src={kinder4} alt="kindergarden" />
            
            {/* <img className='album-image' src={kinder5} alt="kindergarden" /> */}
            <img className='album-image' src={kinder6} alt="kindergarden" />
            <img className='album-image' src={kinder2} alt="kindergarden" />
            {/* <img className='album-image' src={kinder7} alt="kindergarden" /> */}
            <img className='album-image' src={kinder8} alt="kindergarden" />
            <img className='album-image' src={kinder9} alt="kindergarden" />
        </Masonry>
  )
}

export default PhotosAlbum
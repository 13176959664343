import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { house1 } from "../../../assets/houseOfJoy";
import { social1, social2, social3 } from "../../../assets/social";
import Footer from "../../Footer/Footer";

const SocialServices = (props) => {
  const { t } = props;

  return (
    <>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${house1})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Social.SocialTitle")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item">{t("Social.SocialTitle")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={social3} alt="socialservice" />
                  </div>
                </Reveal>

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Social.SocialTitlePage")}</h2>
                    </div>
                  </Reveal>

                  <div className="section-text-p">
                    <Reveal>
                      <p>{t("Social.SocialDesc1")}</p>
                    </Reveal>

                    <Reveal>
                      <p>{t("Social.SocialDesc2")}</p>
                    </Reveal>

                    <Reveal>
                      <p>{t("Social.SocialDesc3")}</p>
                    </Reveal>

                    <Reveal>
                      <p>{t("Social.SocialDesc4")}</p>
                    </Reveal>

                    <Reveal>
                      <div className="image-row">
                        <div className="image-col">
                          <img src={social1} />
                        </div>
                        <div className="image-col">
                          <img src={social2} />
                        </div>
                      </div>
                    </Reveal>

                    <Reveal>
                      <p>{t("Social.SocialDesc5")}</p>
                    </Reveal>

                    {/* <Reveal>
                      <p>{t("Social.SocialDesc6")}</p>
                    </Reveal> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(SocialServices);

import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { motion } from "framer-motion";

import "../styles/homepage.css";
import { Box, Button } from "@mui/material";
import Slider from "react-slick";
import HeroSlider from "hero-slider/dist/HeroSlider";
import { Overlay, Slide } from "hero-slider";
import { slidder1, slidder2, slidder3 } from "../../assets";
import Balloon from "../../utils/custom/animations/ballons";
import { banner30 } from "../../assets/shapes";

const colors = [
  "#FF5733", // Red-Orange
  "#33FF57", // Lime Green
  "#3357FF", // Bright Blue
  "#FF33A6", // Pink
  "#33FFF2", // Aqua
  "#F2FF33", // Yellow
  "#FF8C33", // Orange
  "#8C33FF", // Purple
  "#FF3385", // Magenta
  "#33FF8C", // Mint Green
  "#338CFF", // Sky Blue
  "#FF5733", // Coral
  "#33FF57", // Neon Green
  "#3357FF", // Electric Blue
  "#FFC733", // Gold
  "#FF33C7", // Hot Pink
  "#C733FF", // Violet
  "#33FFC7", // Turquoise
  "#C7FF33", // Lime
  "#FF3333"  // Red
];

const Homepage = (props) => {
  const { t } = props;
  const [imageNumber, imageNumberSet] = useState(0);
  const [balloons, setBalloons] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const effectRan = useRef(false);


  const [sliderHeight, setSliderHeight] = useState("100vh");

  // Function to adjust the slider height based on screen width
  const updateSliderHeight = () => {
    if (window.innerWidth <= 480) {
      setSliderHeight("40vh"); // For phones
    } else if (window.innerWidth <= 768) {
      setSliderHeight("60vh"); // For tablets
    } else {
      setSliderHeight("100vh"); // Default for larger screens
    }
  };

  useEffect(() => {
    // Set the initial height
    updateSliderHeight();

    // Update height on screen resize
    window.addEventListener("resize", updateSliderHeight);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateSliderHeight);
  }, []);


  useEffect(() => {

    if (effectRan.current) return

    let currentCount = 0;
    const maxCount = 10;

    const interval = setInterval(() => {

      currentCount = currentCount+1;
      createBallons(1);

      if (currentCount >= maxCount) {
        clearInterval(interval)
      }

    }, 1000); // New balloon every second

  }, []);

  const createBallons = (nr) => {
    // for (var i = nr; i > 0; i--) {
    //   const color = colors[Math.floor(Math.random() * colors.length)];
    //   const left = Math.floor(Math.random() * 100);
    //   const animationDuration = Math.random() * 5 + 5; // Random duration between 5 and 10 seconds
    //   const balloonId = Date.now() + i; // Unique identifier for each balloon
    //   setIsFirst(false);
    //   setBalloons((prevBalloons) => [
    //     ...prevBalloons,
    //     { balloonId, color, left, animationDuration },
    //   ]);
    // }
  };

  const removeBalloon = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.remove();
      createBallons(1);
    }
  };

  const imageSlide = [{ url: slidder1 }, { url: slidder2 }, { url: slidder3 }];

  const bgImageStyle = (imN) => ({
    backgroundColor: "rgba(0,0,0,0.33)",
    height: "56vw",
    filter: "brightness(75%)",
    width: "1920px",
    zIndex: "1",
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      {balloons.map((balloon, index) => (
        <Balloon
          key={index}
          id={balloon.balloonId}
          color={balloon.color}
          left={balloon.left}
          animation={balloon.animationDuration}
          onAnimationEnd={() => removeBalloon(balloon.balloonId)}
        />
      ))}

      <div className="heroSlidder">
        <HeroSlider
          height={sliderHeight}
          autoplay
          controller={{
            initialSlide: 1,
            slidingDuration: 1000,
            slidingDelay: 100,
          }}
        >
          <Overlay>
            <div className="intro">
              <h1 className="banner-text">
                {t("Homepage.TouchingLives")} & {t("Homepage.BuildingFutures")}
                {/* {t("Homepage.TouchingLives")} <br /> & <br /> {t("Homepage.BuildingFutures")} */}
              </h1>
              {/* <div className="banner30">
                <img src={banner30} alt="image"/>
              </div> */}
            </div>

            <div className="mouse-container">
              <div className="mouse">
                <motion.div
                  animate={{ y: [0, 10, 0] }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "loop",
                  }}
                  className="mouse-animation"
                />
              </div>
            </div>
          </Overlay>

          <Slide
            style={{ filter: "brightness(75%)" }}
            background={{
              backgroundImageSrc: imageSlide[0].url,
            }}
          />
          <Slide
            style={{ filter: "brightness(75%)" }}
            background={{
              backgroundImageSrc: imageSlide[1].url,
            }}
          />
          <Slide
            style={{ filter: "brightness(75%)" }}
            background={{
              backgroundImageSrc: imageSlide[2].url,
            }}
          />
        </HeroSlider>
      </div>
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Homepage);

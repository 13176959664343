import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { albestiHouse } from "../../../assets";
import {
  house1,
  house2,
  house3,
  house4,
  house5,
  house6,
  house7,
  house8,
} from "../../../assets/houseOfJoy";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Footer from "../../Footer/Footer";
import Reveal from "../../../utils/motion/reveal";

const HouseOfJoy = (props) => {
  const {t} = props;

  return (
    <>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${house6})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("HouseOfJoy.HouseOfJoy")}
                  </span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.Projects")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("HouseOfJoy.HouseOfJoy")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>
        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={house1} alt="sighisoara" />
                  </div>
                </Reveal>

                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>
                        {t("HouseOfJoy.PreschoolTitle")}
                      </h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>
                        {t("HouseOfJoy.PreschoolDesc1")}
                      </p>

                      <p>
                      {t("HouseOfJoy.PreschoolDesc2")}
                      </p>

                      <p>{t("HouseOfJoy.PreschoolDesc3")}</p>

                      <p>{t("HouseOfJoy.PreschoolDesc4")}</p>

                      <ul>
                        <li>
                          {t("HouseOfJoy.PreItem1")}
                        </li>
                        <li>{t("HouseOfJoy.PreItem2")}</li>
                        <li>{t("HouseOfJoy.PreItem3")}</li>
                        <li>{t("HouseOfJoy.PreItem4")}</li>
                        <li>{t("HouseOfJoy.PreItem5")}</li>
                        <li>{t("HouseOfJoy.PreItem6")}</li>
                        <li>{t("HouseOfJoy.PreItem7")}</li>
                        <li>{t("HouseOfJoy.PreItem8")}</li>
                        <li>
                          {t("HouseOfJoy.PreItem9")}
                        </li>
                      </ul>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            {/* <div className="section-col"></div> */}
          </div>
        </section>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={house3} />
                  </div>
                </Reveal>
                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("HouseOfJoy.TeensTitle")}</h2>
                    </div>
                  </Reveal>
                  <Reveal>
                    <div className="section-text-p">
                      <p>
                        {t("HouseOfJoy.TeensDesc1")}
                      </p>

                      <p>
                      {t("HouseOfJoy.TeensDesc2")}
                      </p>

                      <p>
                      {t("HouseOfJoy.TeensDesc3")}
                      </p>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </section>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={house2} alt="parents" />
                  </div>
                </Reveal>
                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("HouseOfJoy.Parents")}</h2>
                    </div>
                  </Reveal>
                  <div className="section-text-p">
                    <Reveal>
                      <p>
                        {t("HouseOfJoy.ParentsDesc1")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("HouseOfJoy.ParentsDesc2")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("HouseOfJoy.ParentsDesc3")}
                      </p>
                    </Reveal>
                    <Reveal>
                      <div className="image-row">
                        <div className="image-col">
                          <img src={house4} />
                        </div>
                        <div className="image-col">
                          <img src={house5} />
                        </div>
                      </div>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("HouseOfJoy.ParentsDesc4")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <div className="image-row">
                        <div className="image-col">
                          <img src={house7} />
                        </div>
                        <div className="image-col">
                          <img src={house8} />
                        </div>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(HouseOfJoy);

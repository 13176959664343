import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Reveal from "../../../utils/motion/reveal";
import { house1, house2, house3, house4, house5, house6 } from "../../../assets/daycare";
import Footer from "../../Footer/Footer";

const Daycare = (props) => {
  const {t} = props;

  return (
    <>
      <div className="page-container">
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${house1})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="row">
            <h1>
              <Reveal>
                <span className="color-big-heading">{t("Daycare.DaycareCenter")}</span>
              </Reveal>
            </h1>
            <Reveal>
              <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">Projects</li>
                <li className="breadcrumb-item">
                  <ArrowForwardIcon></ArrowForwardIcon>
                </li>
                <li className="breadcrumb-item ">Daycare Center</li>
              </ul>
            </Reveal>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <Reveal>
                  <div className="section-image">
                    <img src={house2} alt="sighisoara" />
                  </div>
                </Reveal>

                <div className="section-text">
                  <div className="section-text-title">
                    <h2>{t("Daycare.DaycareCenterTitle")}</h2>
                  </div>

                  <div className="section-text-p">
                    <Reveal>
                      <p>
                        {t("Daycare.DaycareDesc1")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("Daycare.DaycareDesc2")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("Daycare.DaycareDesc3")}
                      </p>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("Daycare.DaycareDesc4")}
                      </p>
                    </Reveal>

                    <Reveal>
                    <div className="image-row">
                      <div className="image-col">
                        <img src={house4} />
                      </div>
                      <div className="image-col">
                        <img src={house5} />
                        <img src={house6}/>
                      </div>
                    </div>
                    </Reveal>

                    <Reveal>
                      <p>
                      {t("Daycare.DaycareDesc5")}
                      </p>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Daycare);

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";

import {poza1,poza2,poza3,poza4,poza5,poza6,poza7,poza8,poza10,poza11,poza12} from '../../../assets/news-images/warm feet 2023'
import {poza13,poza14,poza15,poza16,poza17,poza18} from '../../../assets/news-images/warm feet 2023'
import {poza19,poza20,poza21,poza22,poza23,poza24,poza25,poza26,poza27,poza28,poza29,poza30,singlePoza,singlePoza2 } from "../../../assets/news-images/warm feet 2023";


import "../../styles/dialog.css";
import ImageSlider from "../../../utils/image-slider/image-slider";
import Slide from '@mui/material/Slide';
import ColorButton from "../../../utils/custom/colorButton";
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
 });

const Dialog1 = (props) => {
  const { open1, handleClose } = props;

  const images = [poza1,poza2,poza3,poza4,poza5,poza6,poza7,poza8,poza10,poza11,poza12];
  const images2 = [poza13,poza14,poza15,poza16,poza17,poza18];
  const images3 = [poza19,poza20,poza21,poza22,poza23,poza24,poza25,poza26,poza27,poza28,poza29,poza30]

  return (
    <div>
      <Dialog open={open1} maxWidth={"xl"} TransitionComponent={Transition}>
        <Box sx={{display: "flex",
          justifyContent:"flex-end"
        }}>
          <IconButton
              onClick={handleClose}
              aria-label="close"
          >
              <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height:"40px",
            // borderBottomStyle: "solid"
          }}
        >
          <h3 style={{ paddingBottom:"20px",fontSize: "2rem", fontFamily: "'Poppins', sans-serif" }}>
            Warm Feet 2023
          </h3>
        </DialogTitle>
        <DialogContent sx={{margin:"20px"}}>
          <div className="dialog-container">
            <div className="dialog-container-content">
              <p>
                Once again, the project was a blessing for thousands of children
                and families in need, as well was bringing comfort and help for
                those living in difficult circumstances around us. When wet and
                cold outside to be warm and able to go out and play, go to
                school or just get wood for the heat its really great to have
                good shoes and warm cloth.
              </p>
            </div>

            <div>
              <ImageSlider images={images} />
            </div>
            <div className="dialog-container-content">
              <p>
                Beside practical help at Warm Feet we always do a lot of fun
                thing with the children, giving them a joyful time with many
                games and also some exciting drama moments to learn about the
                memorable unique story of Jesus birthday. 
              </p>

              <p>The message this year
                was that at Christmas, we celebrate Jesus, not only being born
                but being the One who brough preciouse gifts like: salvation
                from our sinful life, hope, and possibility to a new life. All
                those who visited Jesus at his birthplace celebrated Him:
                shepherds, the 3 kings, as well as the angels. We still wonder
                how much today time people think of those unprecedent gifts when
                Christmas comes. Do people really know what Christasm is about?</p>
            </div>

            <div>
              <ImageSlider images={images2} />
            </div>

            <div className="dialog-container-content">
              <p>
                The project reached out to 18 villages and we met and blessed
                over 3500 people Thanks to the committed volunteers from
                Switzerland , Belgium and Romania, we could serve and help so
                many. Besides our direct outreaches, we also blessed other
                locations in January together with our partners, friend's
                organizations, or churches so even more people could receive
                help.
              </p>
            </div>

            <div>
              <img src={singlePoza} alt={"team"} />
            </div>

            <div className="dialog-container-content">
              <p>
                In the Tiglarie community, a very poor part of Dumbraveni little
                town, we visited families to invite them out along the one
                street village. During children program one little boy was
                freezing in his summery T-shirt and pajamas type pants, with no
                socks or boots on his feet. Lidia noticed the child's little
                body shaking, so she took the boy by the hand to one of our
                volunteer's named Krista, whom she knew could look for some warm
                clothing. It is not always certain that we have all the needed
                clothes in all sizes, but this time there was a sack in one of
                the vans that had everything the child needed in the right size:
                pants, pullover, jacket, as well we could give him socks and
                boots. Usually, it is hard to help one child before all the
                others, as everyone is in such great need. But this time, all
                the children around him were happy to see him warming up and
                able to participate in the program. Somebody up there, knew to
                set it up especially for this little boy who needed.
              </p>

              <p>
                It is so good to be able to help, to make a difference in times of need.
              </p>

              <div>
                <ImageSlider images={images3} />
              </div>

              <p>
                 Next time, welcome to join with us, it is a joy to give, share, be together and make a difference!
              </p>
            </div>


          </div>
          
          <p>Posted on FEBRUARY 6, 2024</p>
        </DialogContent>
        <DialogActions>
          <ColorButton sx={{margin:"20px"}} onClick={handleClose}>Close</ColorButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dialog1;

import React from 'react'

import '../../components/styles/navbar.css'
import ColorButton from './colorButton'
import { Link } from 'react-router-dom'

const DonationButton = () => {
  return (
    <div className="donation-button-container">
      <Link to={"/donation"}>
        <button className="donation-button-style">Donate</button>
      </Link>
        
    </div>
  )
}

export default DonationButton

import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { kinderGarden } from "../../../assets/sighisoara";

import PhotosAlbum from "./PhotosAlbum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../styles/sighisoara.css";
import Footer from "../../Footer/Footer";
import Reveal from "../../../utils/motion/reveal";

const Kindergarden = (props) => {
  const { t } = props;

  return (
    <>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${kinderGarden})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">
                    {t("Kindergarden.KindergartenTitle")}
                  </span>
                </Reveal>
              </h1>

              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.HowWeWork")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.Education")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">
                    {t("Kindergarden.KindergartenTitle")}
                  </li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div>
                <div className="section-text">
                  <Reveal>
                    <div className="section-text-title">
                      <h2>{t("Kindergarden.KindergartenTitle")}</h2>
                    </div>
                  </Reveal>

                  <Reveal>
                    <div className="section-text-p">
                      <p>{t("Kindergarden.KinderDesc1")}</p>

                      <p>{t("Kindergarden.KinderDesc2")}</p>

                      <p>{t("Kindergarden.KinderDesc3")}</p>

                      <p>{t("Kindergarden.KinderDesc4")}</p>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <div className="bottom-padding-10">
          <section className="album">
            <PhotosAlbum />
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Kindergarden);

import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { house } from "../../../assets/contact";
import Reveal from "../../../utils/motion/reveal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import "../../styles/contact.css";
import Footer from "../../Footer/Footer";

const Contacts = (props) => {
  const {t} = props;
  return (
    <>
      <div className="page-container">
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${house})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="row">
            <h1>
              <Reveal>
                <span className="color-big-heading">{t("Contact.Contact")}</span>
              </Reveal>
            </h1>
            <Reveal>
              <ul className="breadcrumb-wrapper">
                <li className="breadcrumb-item">{t("Contact.AboutUs")}</li>
                <li className="breadcrumb-item">
                  <ArrowForwardIcon></ArrowForwardIcon>
                </li>
                <li className="breadcrumb-item ">{t("Contact.Contact")}</li>
              </ul>
            </Reveal>
          </div>
        </div>

        <section className="padding-top-100">
          <div className="contact-container">
            <Reveal>
              <div className="contact-container-title">
                <p>{t("Contact.ContactUs")}</p>
                <h2>{t("Contact.GetTouch")}</h2>
              </div>
            </Reveal>
            <div className="contact-row">
              <div>
                <Reveal>
                  <div className="contact_item">
                    <div className="flex-row">
                      <LocationOnIcon className="icon-styled"></LocationOnIcon>
                      <h3>{t("Contact.Adress")}: Strada Zugravilor 19, Sighisoara, Romania</h3>
                    </div>
                  </div>
                </Reveal>

                <Reveal>
                  <div className="contact_item">
                    <div className="flex-row">
                      <PersonIcon className="icon-styled"></PersonIcon>

                      <h3>
                      {t("Contact.Public")}: Ligia Pintican Englof
                      </h3>
                    </div>

                    <div>
                      <p>{t("Contact.Phone")}: 0046 763 246 001</p>
                    </div>

                    <a
                      style={{ color: "black" }}
                      href="mailto:lgpenglof@gmail.com"
                    >
                      lgpenglof@gmail.com
                    </a>
                  </div>
                </Reveal>
              </div>

              <div>
                <Reveal>
                  <div className="contact_item">
                    <div className="flex-row">
                      <LocationCityIcon className="icon-styled"></LocationCityIcon>
                      <h3>{t("Contact.Office")}</h3>
                    </div>

                    <div>
                      <p>{t("Contact.Phone")}:</p>
                      <p>0040 774 533 859</p>
                      <p>0040 783 262 555</p>
                      
                      <a
                      style={{ color: "black" }}
                      href="mailto:mmsighi@gmail.com"
                    >
                      mmsighi@gmail.com 
                    </a>
                    </div>
                  </div>
                </Reveal>

                <Reveal>
                  <div className="contact_item">
                    <div className="flex-row">
                      <PersonIcon className="icon-styled"></PersonIcon>
                      <h3>{t("Contact.Project")}: Dionis Bodiu</h3>
                    </div>
                    <p>{t("Contact.Phone")}: +40721-215425</p>
                    <a
                      style={{ color: "black" }}
                      href="mailto:dyobodiu@gmail.com"
                    >
                      dyobodiu@gmail.com
                    </a>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Contacts);
